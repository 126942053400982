import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import PageBuilder from '../../components/common/PageBuilder/PageBuilder'
import PageIntro from '../../components/common/PageIntro'
import { Helmet } from 'react-helmet'

const Article = ({ data: { strapiProducts } }) => {
	return (
		<Layout>
			<Helmet>
				<title>Delta Insurance | Products | {strapiProducts.Title}</title>
				<meta name="description" content={strapiProducts.Paragraph} />
			</Helmet>
			<h1 class="visually-hidden">{strapiProducts.Title}</h1>
			<PageIntro
				title={strapiProducts.Title ? strapiProducts.Title : undefined}
				summary={
					strapiProducts.Paragraph ? strapiProducts.Paragraph : undefined
				}
				image={strapiProducts.HeroImage ? strapiProducts.HeroImage : undefined}
				buttonText={
					strapiProducts.ButtonText ? strapiProducts.ButtonText : undefined
				}
				buttonLink={
					strapiProducts.ButtonUrl ? strapiProducts.ButtonUrl : undefined
				}
			/>
			{strapiProducts.pageBuilder && (
				<PageBuilder data={strapiProducts.pageBuilder} />
			)}
		</Layout>
	)
}

export default Article

export const query = graphql`
	query ProductsQuery($Slug: String!) {
		strapiProducts(Slug: { eq: $Slug }) {
			id
			pageBuilder
			Paragraph
			Slug
			Title
			ButtonText
			ButtonUrl
			HeroImage {
				provider_metadata {
					public_id
				}
				formats {
					medium {
						url
					}
					small {
						url
					}
					thumbnail {
						url
					}
				}
			}
		}
	}
`
